import React from "react";

const Contactus = () => {
  return (
    <div className="contact" id="contact">
      <div className="container">
        <div className="row gy-4 align-items-center">
          <div className="col-md-6 col-lg-4">
            <div className="contact-img">
              <img src="/img/get-touch.png" />
            </div>
          </div>
          <div className="col-md-3 col-lg-4 d-none d-lg-block"/>
          <div className="col-md-6 col-lg-4">
            <div className="section_title">
              <h2>Get in touch and find out how we can help</h2>
            </div>
            <div className="input_form">
              <a href="tel:(253) 473-4776">Call (253) 473-4776</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
