import React from "react";

const Services = () => {
  return (
    <div className="services" id="services">
      <div className="container">
        <div className="row">
          <h2 className="section-title">
            Various service we provide <br /> to make beauty bouquete
          </h2>
        </div>
        <div className="row gy-5">
          {servicesList.map((svc) => (
            <div className="col-md-3" key={svc?.src}>
              <div className="service-box">
                <div className="service-box-title">
                  <div className="before-title" />
                  <h6>{svc?.title}</h6>
                </div>
                <div className="service-content">
                  <img src={svc?.src}/>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;

const servicesList = [
  { title: "Men's Hair", src: "/img/men-hair.jpg" },
  { title: "Women's Hair", src: "/img/woman-hair.jpg" },
  { title: "Perm", src: "/img/perm.jpg" },
  { title: "Spiro", src: "/img/spiro.jpg" },
  { title: "Color", src: "/img/color.jpg" },
  { title: "Highlights", src: "/img/highlight.jpg" },
  { title: "Facial", src: "/img/facial.jpg" },
  { title: "Mai Da", src: "/img/maidai.jpg" },
];
