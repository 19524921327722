import React from "react";
import {
  Grow,
  Hero,
  Services,
  Benefit,
  Footer,
  Header,
  Testimonials,
  ContactUs,
} from "../components";

const Home = () => {
  return (
    <div id="landing_page">
      <Header />
      <Hero />
      <Services />
      <Grow />
      <Benefit />
      <Testimonials />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
