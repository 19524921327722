import React from "react";
import { Route } from "react-router-dom";
// import userRole from "../constants/userRole";

// const CustomLayout = ({
//   component: Component,
//   privateRoute = true,
//   authRoute = false,
//   ...rest
// }) => (
//   <Route
//     {...rest}
//     component={(props) => {
//       if (authRoute) {
//         return <Component {...props} />;
//       } else if (privateRoute) {
//         return <Component {...props} />;
//       } else return <Component {...props} />;
//     }}
//   />
// );

// public component
export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} component={(props) => <Component {...props} />} />
);

// auth component
// export const AuthRoute = ({ component: Component, ...rest }) => {
//   const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
//   return isAuthenticated ? (
//     <Redirect to="/create-appointment" />
//   ) : (
//     <AuthLayout>
//       <Route {...rest} component={(props) => <Component {...props} />} />
//     </AuthLayout>
//   );
// };

// authenticated route
// export const PrivateRoute = ({ component: Component, ...rest }) => {
//   const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
//   return isAuthenticated ? (
//     <PrivateLayout>
//       <Route {...rest} component={(props) => <Component {...props} />} />
//     </PrivateLayout>
//   ) : (
//     <Redirect to="/" />
//   );
// };

// export const CommonRoute = ({ component: Component, ...rest }) => {
//   const userAuthenticated = useSelector(
//     (state) => state?.auth?.isAuthenticated
//   );
//   return userAuthenticated ? (
//     <Route {...rest} component={(props) => <Component {...props} />} />
//   ) : (
//     <Redirect to="/" />
//   );
// };

// shopper component route
