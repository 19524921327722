import React from "react";
import { createBrowserHistory } from "history";
import { Switch, Router } from "react-router-dom";

// Index Routes
import { Public } from "./Router";
import { PublicRoute } from "./AllRoute";
// import { Layout } from "../containers/Layout";
// Routes

// Components
export const history = createBrowserHistory();

const RouterConfig = () => {
  return (
    <Router history={history}>
      <Switch>
        {/* {Private.map((R, k) => {
          return <PrivateRoute key={k} {...R} />;
        })} */}
        {/* {Auth.map((R, k) => {
          return <AuthRoute key={k} {...R} />;
        })} */}

        {Public.map((R, k) => {
          return <PublicRoute key={k} {...R} />;
        })}
        {/* {Common.map((R, k) => {
          return <CommonRoute key={k} {...R} />;
        })} */}
        {/* <Route component={NotFound} /> */}
      </Switch>
    </Router>
  );
};

export const AppRouter = RouterConfig;
