import React from "react";

const Header = () => {

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction=()=> {
    if (
      document.body.scrollTop > 101 ||
      document.documentElement.scrollTop > 101
    ) {
      document.getElementById("header").classList.add("navScroll");
    } if(document.documentElement.scrollTop<80){
      document.getElementById("header").classList.remove("navScroll");
    }
  }
  return (
    <div className="header" id="header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg navbar-light" id="navbar">
              <a className="navbar-brand" href="#">
                <img src="/img/logo-p.png" alt="" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item me-3">
                    <a href="#hero" className="nav-link">
                      Home
                    </a>
                  </li>
                  <li className="nav-item me-3">
                    <a href="#services" className="nav-link">
                      Services
                    </a>
                  </li>
                  <li className="nav-item me-3">
                    <a
                      className="nav-link"
                      href="#grow"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item me-3">
                    <a
                      className="nav-link"
                      href="#testimonials"
                    >
                      Reviews
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link"  href="tel:(253) 473-4776">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
