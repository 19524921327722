import {
  Home,
} from "../pages";

// Login or Sign in Routes
// export const Auth = [
//   { path: "/signup", exact: true, component: SignUpPage },
//   { path: "/signin", exact: true, component: SignIn },
//   { path: "/forgot-password", exact: true, component: ForgotPassword },
//   { path: "/reset-password/:id", component: ResetPassword },

//   { path: "/", exact: true, component: Home },
// ];

// Public Routes
export const Public = [
  { path: "/", exact: true, component: Home },
];
// Private Routes
export const Private = [
];

export const Common = [];
