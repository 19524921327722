import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { AppRouter } from "./routes/AppRouter";
import "antd/dist/antd.css";
import './styles/index.scss';

ReactDOM.render(<AppRouter />, document.getElementById("root"));

// serviceWorker.register();
serviceWorker.unregister();
